import CoreTabs from '@nrk/core-tabs/jsx';
import classNames from 'classnames';
import { Button, IButtonProps, ILinkProps } from '../Button/Button';
import './ButtonGroup.scss';

export interface IGroupedButtonProps extends Omit<IButtonProps, 'buttonSize' | 'buttonType' | 'buttonVariant'> {
  buttonId: string;
  text: string;
}

export interface IGroupedLinkProps extends Omit<ILinkProps, 'buttonSize' | 'buttonType' | 'buttonVariant'> {
  buttonId: string;
  text: string;
}

interface IBaseProps {
  className?: string;
  background?: 'base' | 'lower';
  buttons: IGroupedButtonProps[] | IGroupedLinkProps[];
  activeButtonId: string;
  onChange?: (button: IGroupedButtonProps | IGroupedLinkProps) => void;
  width?: 'compact' | 'auto';
  buttonSize?: 'small' | 'medium' | 'large';
  removeGap?: boolean;
  center?: boolean;
}

export function ButtonGroup(props: IBaseProps) {
  const {
    background = 'base',
    buttons,
    buttonSize = 'small',
    removeGap = false,
    width = 'auto',
    center = true,
    className,
    activeButtonId,
    onChange
  } = props;

  const buttonGroup = buttons.map(button =>
    button.as === 'button' ? (
      <Button
        as="button"
        className={classNames('button-group__button', button.className)}
        buttonSize={buttonSize}
        buttonType={activeButtonId === button.buttonId ? 'ghost' : 'bleed'}
        buttonVariant={activeButtonId === button.buttonId ? 'accent' : 'neutral'}
        ariaLabel={button.ariaLabel}
        key={button.buttonId}
        htmlFor={button.htmlFor}
        onClick={() => {
          if (onChange != null) {
            onChange(button);
          }
        }}
      >
        {button.text}
      </Button>
    ) : (
      <Button
        as="link"
        className={classNames('button-group__button', button.className)}
        href={button.href}
        buttonSize={buttonSize}
        buttonType={activeButtonId === button.buttonId ? 'ghost' : 'bleed'}
        buttonVariant={activeButtonId === button.buttonId ? 'accent' : 'neutral'}
        ariaLabel={button.ariaLabel}
        key={button.buttonId}
        htmlFor={button.htmlFor}
        onClick={() => {
          if (onChange != null) {
            onChange(button);
          }
        }}
      >
        {button.text}
      </Button>
    )
  );

  // TODO(tl): Should we use CoreTabs in here?
  // Comment(as): No, we should not use CoreTabs here, we should switch to <u-tabs>: https://u-elements.github.io/u-elements/elements/u-tabs
  // Switching to u-elements is a separate task, see: https://nrknyemedier.atlassian.net/browse/YR-7579
  return (
    <CoreTabs>
      <div
        className={classNames(
          'button-group',
          {
            // If there is only one button we hide the button group
            'button-group--hidden': buttons.length <= 1
          },
          className
        )}
        data-size={buttonSize}
        data-center={center}
        data-testid="button-group"
      >
        <div
          className="button-group__content"
          data-width={width}
          data-remove-gap={removeGap}
          data-background={background}
        >
          {buttonGroup}
        </div>
      </div>
    </CoreTabs>
  );
}
