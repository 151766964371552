import { TIconId } from '@nrk/yr-icons';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { IconButton } from '../IconButton/IconButton';
import './MapIconButton.scss';

interface IMapIconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  icon: TIconId;
  ariaLabel: string;
  elevated?: boolean;
  buttonSize?: 'small' | 'medium';
  // We are removing children here because in IconButton we have set children to never.
  children?: never;
}

export const MapIconButton = forwardRef<HTMLButtonElement, IMapIconButtonProps>((props, ref) => {
  const { className, icon, ariaLabel, elevated = true, buttonSize = 'medium', ...htmlProps } = props;
  return (
    <IconButton
      className={classNames('map-icon-button', className)}
      data-elevated={elevated}
      buttonType={'bleed'}
      buttonVariant={'neutral'}
      as="button"
      ref={ref}
      icon={icon}
      buttonSize={buttonSize}
      ariaLabel={ariaLabel}
      {...htmlProps}
    />
  );
});
